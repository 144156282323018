@import "./styles/mixins.scss";

* {
  margin: 0;
  padding: 0;
}

.App {
  overflow-x: hidden;

  // .topEdge {
  //   position: fixed;
  //   top: -4px;
  //   transform: rotate(180deg);
  //   width: 100%;
  //   z-index: 100;
  //   img {
  //     width: 100%;
  //   }
  // }
  // .bottomEdge {
  //   position: fixed;
  //   bottom: -4px;
  //   width: 100%;
  //   z-index: 100;
  //   img {
  //     width: 100%;
  //   }
  // }

  #bgVid {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;
  }
  #bgVid::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #1b1b1b;
    opacity: 0.8;
  }
  .content {
    .downloadButton {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: 100;
      opacity: 0;
      @include resp(1380px) {
        position: absolute;
      }
    }
  }
}
