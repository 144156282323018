@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.homeWrapper {
  .homeContent {
    font-size: 9rem;
    display: grid;
    place-items: center;
    height: 100vh;

    animation: glitch 4000ms infinite;

    .myName {
      h1 {
        color: $main-yellow;
        line-height: 140px;
        font-weight: 900;
        font-size: 200px;
        @include resp(1400px) {
          font-size: 160px;
        }
        @include resp(888px) {
          font-size: 100px;
        }
        @include resp(530px) {
          font-size: 70px;
        }
      }
      h6 {
        font-weight: 900;
        letter-spacing: 10px;
        font-size: 37px;
        text-align: right;
        line-height: 0.5;
        color: white;
        margin-top: 26px;
        @include resp(1400px) {
          font-size: 24px;
          margin-top: 5px;
          margin-right: 0px;
        }

        @include resp(888px) {
          font-size: 20px;
          margin-top: -16px;
          margin-right: -5px;
        }
        @include resp(530px) {
          margin-top: -33px;
          font-size: 14px;
        }
      }
    }
  }
}
@keyframes glitch {
  0% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  13% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  14% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  15% {
    text-shadow: 0.03em 0.02em 0 #00ccff, -0.03em -0.02em 0 #e500a6;
  }
  16% {
    text-shadow: -0.04em -0.04em 0 #00ccff, 0.04em 0.04em 0 #e500a6;
  }
  17% {
    text-shadow: -0.09em -0.04em 0 #00ccff, 0.09em 0.04em 0 #e500a6;
  }
  18% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  19% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }

  78% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  79% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  80% {
    text-shadow: 0.03em 0.02em 0 #00ccff, -0.03em -0.02em 0 #e500a6;
  }
  81% {
    text-shadow: -0.04em -0.04em 0 #00ccff, 0.04em 0.04em 0 #e500a6;
  }
  82% {
    text-shadow: -0.09em -0.04em 0 #00ccff, 0.09em 0.04em 0 #e500a6;
  }
  83% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  84% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }

  100% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
}
