@import "~/src/styles/colors.scss";
@import "~/src/styles/mixins.scss";
@import "~/src/styles/constants.scss";
.projects {
  margin-top: -25px;
  &__content {
    .topEdge {
      margin-bottom: -6px;
      img {
        width: 100%;
      }
    }
    .body {
      min-height: 100vh;
      padding: 340px 50px 0px 50px;
      background-color: $main-yellow;
      margin: 0 auto;

      &__content {
        max-width: $max-content-width;
      }
    }
    .bottomEdge {
      transform: rotate(180deg);
      margin-top: -6px;
      img {
        width: 100%;
      }
    }
  }
}
