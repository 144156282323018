@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.introWrapper {
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;

  .introContent {
    .introText {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 1rem;
      @include resp(400px) {
        font-size: 12px;
      }
      @include resp(360px) {
        font-size: 10px;
      }
      .introTextItem {
        .glitch {
          animation: glitchIntro 1s infinite;
        }
      }
    }
  }
}

@keyframes glitchIntro {
  0% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  13% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  14% {
    text-shadow: -0.4em -0.4em 0 #00ccff, 0.4em 0.4em 0 #e500a6;
  }
  15% {
    text-shadow: 0.03em 0.02em 0 #00ccff, -0.03em -0.02em 0 #e500a6;
  }
  16% {
    text-shadow: -0.5em -0.5em 0 #00ccff, 0.5em 0.5em 0 #e500a6;
  }
  17% {
    text-shadow: -0.09em -0.04em 0 #00ccff, 0.09em 0.04em 0 #e500a6;
  }
  18% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  19% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }

  78% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  79% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  80% {
    text-shadow: 0.03em 0.02em 0 #00ccff, -0.03em -0.02em 0 #e500a6;
  }
  81% {
    text-shadow: -0.04em -0.04em 0 #00ccff, 0.04em 0.04em 0 #e500a6;
  }
  82% {
    text-shadow: -0.09em -0.04em 0 #00ccff, 0.09em 0.04em 0 #e500a6;
  }
  83% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  84% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }

  100% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
}
