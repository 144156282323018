@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";
@import "../../styles/constants.scss";

.about {
  width: 100%;
  margin-top: -20px;
  &__content {
    .topEdge {
      margin-bottom: -6px;
      img {
        width: 100%;
      }
    }
    .body {
      min-height: 100vh;
      padding: 50px;
      background-color: $main-yellow;
      display: flex;
      justify-content: center;
      align-items: center;
      @include resp(800px) {
        padding: 50px 20px;
      }
      &__content {
        max-width: $max-content-width;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include resp(800px) {
          grid-template-columns: 1fr 2fr;
        }
        @include resp(600px) {
          grid-template-columns: 1fr;
        }
        .sectionTitle {
          text-align: center;
          @include resp(600px) {
            text-align: left;
          }
          .title {
            font-weight: 900;
            font-size: 36px;
            @include resp(800px) {
              font-size: 24px;
            }
            @include resp(600px) {
              font-size: 36px;
              margin-bottom: 50px;
            }
          }
        }
        .texts {
          width: 80%;
          p {
            margin-top: 10px;
            font-weight: 600;
          }
        }
      }
    }
    .bottomEdge {
      transform: rotate(180deg);
      margin-top: -6px;
      img {
        width: 100%;
      }
    }
  }
}
@keyframes glitch {
  0% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  13% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  14% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  15% {
    text-shadow: 0.03em 0.02em 0 #00ccff, -0.03em -0.02em 0 #e500a6;
  }
  16% {
    text-shadow: -0.04em -0.04em 0 #00ccff, 0.04em 0.04em 0 #e500a6;
  }
  17% {
    text-shadow: -0.09em -0.04em 0 #00ccff, 0.09em 0.04em 0 #e500a6;
  }
  18% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  19% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }

  78% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
  79% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  80% {
    text-shadow: 0.03em 0.02em 0 #00ccff, -0.03em -0.02em 0 #e500a6;
  }
  81% {
    text-shadow: -0.04em -0.04em 0 #00ccff, 0.04em 0.04em 0 #e500a6;
  }
  82% {
    text-shadow: -0.09em -0.04em 0 #00ccff, 0.09em 0.04em 0 #e500a6;
  }
  83% {
    text-shadow: -0.03em -0.02em 0 #00ccff, 0.03em 0.02em 0 #e500a6;
  }
  84% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }

  100% {
    text-shadow: 0.02em 0.02em 0 #00ccff, 0em 0em 0 #e500a6;
  }
}
